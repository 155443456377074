// src/App.js

import React, { useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import HomePage from './pages/HomePage';
import PuzzleSolver from './pages/PuzzleSolver';
import AboutPage from './pages/AboutPage';
import Results from './components/Results';
import ErrorBoundary from './components/ErrorBoundary';
import './styles/App.css';

function App() {
  const handleGlobalError = useCallback((error, errorInfo) => {
    console.error('Uncaught error:', error, errorInfo);
  }, []);

  return (
    <ErrorBoundary onError={handleGlobalError}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/solve" element={<PuzzleSolver />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/results" element={<Results />} />
          </Routes>
        </Layout>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
