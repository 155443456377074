import React, { useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ZoomIn, ZoomOut, RotateCw, Lock, Maximize2 } from 'lucide-react';

const PuzzleInterface = ({ processedData }) => {
  const [selectedGap, setSelectedGap] = useState(null);
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [previewRotation, setPreviewRotation] = useState(0);
  const containerRef = useRef(null);

  const findBestMatch = (piece, gap, referenceAnalysis) => {
    // Find the best match from suggestedPlacements
    const match = processedData.suggestedPlacements.find(
      placement => placement.pieceIndex === piece.index &&
      placement.puzzleX === gap.x &&
      placement.puzzleY === gap.y
    );
    return match || { rotation: 0, confidence: 0 };
  };

  const confirmPlacement = (piece, gap, rotation) => {
    // Handle piece placement confirmation
    console.log('Confirming placement:', { piece, gap, rotation });
    // Add your placement confirmation logic here
  };

  const handleGapClick = useCallback((gap) => {
    setSelectedGap(gap);
    // Get suggestions for this gap
    const suggestions = processedData.suggestedPlacements.filter(
      placement => placement.puzzleX === gap.x && placement.puzzleY === gap.y
    );
    setSelectedPiece(null);
    setPreviewRotation(0);
  }, [processedData]);

  const handlePieceClick = useCallback((piece) => {
    setSelectedPiece(piece);
    if (selectedGap) {
      const match = findBestMatch(piece, selectedGap, processedData.referenceAnalysis);
      setPreviewRotation(match.rotation);
    }
  }, [selectedGap, processedData]);

  const renderGapOverlay = () => {
    if (!processedData?.gaps) return null;

    return processedData.gaps.map((gap, index) => (
      <motion.div
        key={`gap-${index}`}
        className={`absolute cursor-pointer ${
          selectedGap === gap ? 'border-4 border-blue-500' : 'border-2 border-yellow-300'
        }`}
        style={{
          left: gap.x * zoomLevel,
          top: gap.y * zoomLevel,
          width: gap.width * zoomLevel,
          height: gap.height * zoomLevel,
          backgroundColor: selectedGap === gap ? 'rgba(59, 130, 246, 0.1)' : 'rgba(252, 211, 77, 0.1)'
        }}
        onClick={() => handleGapClick(gap)}
        whileHover={{ scale: 1.02 }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
      >
        {selectedGap === gap && (
          <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white px-2 py-1 rounded text-sm">
            Click a piece to try here
          </div>
        )}
      </motion.div>
    ));
  };

  const renderPiecePreviews = () => {
    if (!selectedGap || !processedData?.pieceAnalysis) return null;

    return processedData.pieceAnalysis.map((piece, index) => (
      <motion.div
        key={`piece-${index}`}
        className={`relative cursor-pointer ${
          selectedPiece === piece ? 'ring-4 ring-blue-500' : 'hover:ring-2 ring-gray-300'
        }`}
        onClick={() => handlePieceClick(piece)}
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
      >
        <img
          src={piece.imageUrl}
          alt={`Piece ${index + 1}`}
          className="w-24 h-24 object-contain"
          style={{
            transform: selectedPiece === piece ? `rotate(${previewRotation}deg)` : undefined
          }}
        />
        {selectedPiece === piece && (
          <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-2 py-1 rounded text-sm flex items-center">
            <Lock className="w-4 h-4 mr-1" />
            {Math.round(piece.confidence * 100)}% match
          </div>
        )}
      </motion.div>
    ));
  };

  const renderMatchPreview = () => {
    if (!selectedGap || !selectedPiece) return null;

    return (
      <motion.div
        className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 w-80"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <h3 className="text-lg font-semibold mb-2">Match Preview</h3>
        <div className="relative aspect-square">
          <img
            src={processedData.referenceDataUrl}
            alt="Reference section"
            className="absolute top-0 left-0 w-full h-full object-cover opacity-50"
          />
          <motion.img
            src={selectedPiece.imageUrl}
            alt="Selected piece"
            className="absolute top-0 left-0 w-full h-full object-contain"
            animate={{ rotate: previewRotation }}
            transition={{ type: "spring", stiffness: 200, damping: 20 }}
          />
        </div>
        <div className="mt-2 flex justify-between items-center">
          <button
            onClick={() => setPreviewRotation(prev => (prev + 90) % 360)}
            className="flex items-center text-sm text-gray-600 hover:text-blue-500"
          >
            <RotateCw className="w-4 h-4 mr-1" />
            Rotate
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            onClick={() => confirmPlacement(selectedPiece, selectedGap, previewRotation)}
          >
            Confirm Placement
          </button>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="puzzle-interface" ref={containerRef}>
      {/* Main puzzle area */}
      <div className="relative border rounded-lg overflow-hidden">
        <div style={{ transform: `scale(${zoomLevel})` }}>
          <img
            src={processedData?.progressDataUrl}
            alt="Current Progress"
            className="max-w-full"
          />
          {renderGapOverlay()}
        </div>
        
        {/* Zoom controls */}
        <div className="absolute bottom-4 right-4 flex space-x-2 bg-white rounded-lg shadow p-2">
          <button onClick={() => setZoomLevel(prev => prev - 0.1)}>
            <ZoomOut className="w-5 h-5" />
          </button>
          <span className="text-sm text-gray-600">{Math.round(zoomLevel * 100)}%</span>
          <button onClick={() => setZoomLevel(prev => prev + 0.1)}>
            <ZoomIn className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Piece selection panel */}
      <AnimatePresence>
        {selectedGap && (
          <motion.div
            className="fixed right-4 top-1/2 transform -translate-y-1/2 bg-white rounded-lg shadow-lg p-4 w-64"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 100, opacity: 0 }}
          >
            <h3 className="text-lg font-semibold mb-2">Select a Piece</h3>
            <div className="grid grid-cols-2 gap-4">
              {renderPiecePreviews()}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Match preview */}
      {renderMatchPreview()}
    </div>
  );
};

export default PuzzleInterface;