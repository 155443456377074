// src/pages/PuzzleSolver.js

import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PuzzleInterface from '../components/PuzzleInterface';
import PieceUploader from '../components/PieceUploader';
import ImageProcessor from '../components/ImageProcessor';
import SolverControls from '../components/SolverControls';

const PuzzleSolver = () => {
  const navigate = useNavigate();
  const [imageData, setImageData] = useState(null);
  const [processedData, setProcessedData] = useState(null);
  const [error, setError] = useState(null);

  const handleImagesUpload = useCallback((data) => {
    console.log('Images uploaded:', data);
    setImageData({
      referenceImage: data.completeImage,
      currentProgress: data.progressImage,
      remainingPieces: data.remainingPieces
    });
    setProcessedData(null);
    setError(null);
  }, []);

  const handleProcessingComplete = useCallback((data) => {
    console.log('Processing complete:', data);
    setProcessedData(data);
    // TODO: Add a success notification here
  }, []);

  const handleProcessingError = useCallback((errorMessage) => {
    console.error('Processing error:', errorMessage);
    setError(errorMessage);
    setProcessedData(null);
    // TODO: Add an error notification here
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <PieceUploader onImagesUpload={handleImagesUpload} />
      {imageData && (
        <ImageProcessor 
          imageData={imageData} 
          onProcessingComplete={handleProcessingComplete}
          onProcessingError={handleProcessingError}
        />
      )}
      {error && (
        <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
          {error}
        </div>
      )}
      <PuzzleInterface processedData={processedData} />
      <SolverControls processedData={processedData} />
    </div>
  );
};

export default PuzzleSolver;