import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, RotateCw } from 'lucide-react';

const Results = ({ processedData }) => {
  const [currentStep, setCurrentStep] = useState(0);

  if (!processedData?.suggestedPlacements) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
          <p className="text-yellow-700">No puzzle solution available. Please process a puzzle first.</p>
          <Link to="/" className="text-blue-500 hover:text-blue-600 mt-2 inline-block">
            Return to Puzzle Interface
          </Link>
        </div>
      </div>
    );
  }

  const sortedPlacements = [...processedData.suggestedPlacements]
    .sort((a, b) => b.confidence - a.confidence);

  const handlePrevStep = () => setCurrentStep(prev => Math.max(0, prev - 1));
  const handleNextStep = () => setCurrentStep(prev => 
    Math.min(sortedPlacements.length - 1, prev + 1)
  );

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold">Puzzle Solution</h2>
        <Link
          to="/"
          className="text-blue-500 hover:text-blue-600"
        >
          Back to Puzzle Interface
        </Link>
      </div>

      {/* Solution Preview */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-4 border-b">
          <h3 className="font-semibold">Complete Solution Preview</h3>
        </div>
        <div className="relative">
          <img
            src={processedData.referenceDataUrl}
            alt="Reference Solution"
            className="max-w-full opacity-50"
          />
          <img
            src={processedData.progressDataUrl}
            alt="Current Progress"
            className="absolute top-0 left-0 max-w-full"
          />
          {sortedPlacements.slice(0, currentStep + 1).map((placement, index) => (
            <div
              key={`placement-${index}`}
              className="absolute border-2 border-green-500 bg-green-100 opacity-50"
              style={{
                left: placement.puzzleX,
                top: placement.puzzleY,
                width: placement.width,
                height: placement.height,
                transform: `rotate(${placement.rotation}deg)`
              }}
            />
          ))}
        </div>
      </div>

      {/* Step-by-Step Instructions */}
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-4 border-b">
          <h3 className="font-semibold">Step-by-Step Instructions</h3>
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-4">
            <button
              onClick={handlePrevStep}
              disabled={currentStep === 0}
              className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <span className="font-medium">
              Step {currentStep + 1} of {sortedPlacements.length}
            </span>
            <button
              onClick={handleNextStep}
              disabled={currentStep === sortedPlacements.length - 1}
              className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>

          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                <span className="font-medium">Piece {sortedPlacements[currentStep].pieceIndex + 1}</span>
                <div className="flex items-center text-gray-600">
                  <RotateCw className="w-4 h-4 mr-1" />
                  <span>{sortedPlacements[currentStep].rotation}°</span>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-24 bg-gray-200 rounded-full h-2">
                  <div
                    className="bg-blue-500 rounded-full h-2"
                    style={{ width: `${sortedPlacements[currentStep].confidence * 100}%` }}
                  />
                </div>
                <span className="text-sm text-gray-600">
                  {(sortedPlacements[currentStep].confidence * 100).toFixed(1)}%
                </span>
              </div>
            </div>

            <p className="text-gray-600">
              Place this piece at position ({sortedPlacements[currentStep].puzzleX}, 
              {sortedPlacements[currentStep].puzzleY}) in the puzzle grid.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;