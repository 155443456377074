import React from 'react';
import { useNavigate } from 'react-router-dom';

function SolverControls() {
  const navigate = useNavigate();

  const handleSolve = () => {
    // TODO: Implement puzzle solving logic
    console.log('Solving puzzle...');
    // For now, just navigate to results page
    navigate('/results');
  };

  return (
    <div className="solver-controls">
      <h2>Solver Controls</h2>
      <button onClick={handleSolve}>Solve Puzzle</button>
    </div>
  );
}

export default SolverControls;
