import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Image as ImageIcon, 
  PuzzleIcon, 
  Layers, 
  Grid, 
  Search,
  CheckCircle2,
  AlertCircle
} from 'lucide-react';

const ProcessingSteps = {
  INITIALIZING: {
    icon: Layers,
    title: 'Initializing',
    description: 'Preparing images for analysis...'
  },
  ANALYZING_REFERENCE: {
    icon: ImageIcon,
    title: 'Analyzing Reference',
    description: 'Detecting puzzle grid and patterns...'
  },
  DETECTING_GAPS: {
    icon: Grid,
    title: 'Detecting Gaps',
    description: 'Finding empty spaces in current progress...'
  },
  ANALYZING_PIECES: {
    icon: PuzzleIcon,
    title: 'Analyzing Pieces',
    description: 'Identifying and analyzing remaining pieces...'
  },
  MATCHING: {
    icon: Search,
    title: 'Finding Matches',
    description: 'Matching pieces to possible positions...'
  }
};

function ImageProcessor({ imageData, onProcessingComplete, onProcessingError }) {
  const [currentStep, setCurrentStep] = useState('INITIALIZING');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [processingTime, setProcessingTime] = useState(0);

  useEffect(() => {
    let worker = null;
    let timeoutId = null;
    let startTime = null;
    let timerInterval = null;

    const processImages = async () => {
      try {
        setError(null);
        setProgress(0);
        startTime = Date.now();
        
        timerInterval = setInterval(() => {
          setProcessingTime(Math.floor((Date.now() - startTime) / 1000));
        }, 1000);

        worker = new Worker(new URL('./imageProcessingWorker.js', import.meta.url));

        worker.onmessage = (event) => {
          if (event.data.type === 'progress') {
            setCurrentStep(event.data.step);
            setProgress(event.data.progress || 0);
          } else if (event.data.type === 'complete') {
            clearTimeout(timeoutId);
            clearInterval(timerInterval);
            onProcessingComplete(event.data.processedData);
          } else if (event.data.type === 'error') {
            handleError(event.data.message);
          }
        };

        worker.onerror = (error) => {
          handleError(`Worker error: ${error.message}`);
        };

        worker.postMessage({ imageData });

        timeoutId = setTimeout(() => {
          handleError('Processing timed out. Please try again with smaller images.');
        }, 60000);
      } catch (err) {
        handleError(err.message);
      }
    };

    const handleError = (message) => {
      setError(message);
      clearInterval(timerInterval);
      onProcessingError(message);
      if (worker) worker.terminate();
      if (timeoutId) clearTimeout(timeoutId);
    };

    if (imageData) {
      processImages();
    }

    return () => {
      if (worker) worker.terminate();
      if (timeoutId) clearTimeout(timeoutId);
      if (timerInterval) clearInterval(timerInterval);
    };
  }, [imageData, onProcessingComplete, onProcessingError]);

  if (error) {
    return (
      <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <div className="flex items-center justify-center text-red-500 mb-4">
          <AlertCircle className="w-8 h-8 mr-2" />
          <h3 className="text-lg font-semibold">Processing Error</h3>
        </div>
        <p className="text-gray-600 text-center mb-4">{error}</p>
        <div className="text-center">
          <button 
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  const CurrentStepIcon = ProcessingSteps[currentStep]?.icon || Layers;

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <div className="mb-8">
        <div className="flex justify-between mb-4">
          {Object.entries(ProcessingSteps).map(([key, step], index) => {
            const StepIcon = step.icon;
            const isCurrent = key === currentStep;
            const isCompleted = Object.keys(ProcessingSteps)
              .indexOf(currentStep) > Object.keys(ProcessingSteps)
              .indexOf(key);

            return (
              <div 
                key={key}
                className={`flex flex-col items-center w-1/5 ${
                  isCurrent ? 'text-blue-500' : 
                  isCompleted ? 'text-green-500' : 
                  'text-gray-300'
                }`}
              >
                <div className="relative">
                  <StepIcon className="w-6 h-6 mb-2" />
                  {isCompleted && (
                    <CheckCircle2 className="w-4 h-4 absolute -right-1 -bottom-1 text-green-500" />
                  )}
                </div>
                <span className="text-xs text-center">
                  {step.title}
                </span>
              </div>
            );
          })}
        </div>

        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-between">
            <div>
              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                {ProcessingSteps[currentStep].title}
              </span>
            </div>
            <div className="text-right">
              <span className="text-xs font-semibold inline-block text-blue-600">
                {progress.toFixed(0)}%
              </span>
            </div>
          </div>
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-100">
            <motion.div 
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </div>

        <div className="text-center text-gray-500 text-sm">
          <CurrentStepIcon className="w-8 h-8 mx-auto mb-2 text-blue-500" />
          <p>{ProcessingSteps[currentStep].description}</p>
          <p className="mt-2">Processing time: {processingTime} seconds</p>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {Object.entries(imageData).map(([key, image]) => (
          <div key={key} className="relative">
            <img
              src={URL.createObjectURL(image)}
              alt={key}
              className="w-full h-32 object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                className="text-white"
              >
                <Layers className="w-6 h-6" />
              </motion.div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageProcessor;