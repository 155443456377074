import React from 'react';
import { Camera, Puzzle, Share2, Zap } from 'lucide-react';

const HomePage = () => {
  const features = [
    {
      icon: Camera,
      title: "Take Three Photos",
      description: "Upload the puzzle box cover, your current progress, and remaining pieces"
    },
    {
      icon: Zap,
      title: "AI Analysis",
      description: "Our system analyzes patterns, shapes, and colors to find matching pieces"
    },
    {
      icon: Puzzle,
      title: "Interactive Solving",
      description: "Click empty spaces to get suggestions for matching pieces with confidence scores"
    },
    {
      icon: Share2,
      title: "Get Guidance",
      description: "See exactly how pieces should be rotated and where they fit best"
    }
  ];

  const tips = [
    "Ensure good lighting when taking photos",
    "Keep the camera steady and avoid shadows",
    "Spread out loose pieces with space between them",
    "Include the entire puzzle area in progress photos",
    "Use a contrasting background for better piece detection"
  ];

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Jigsaw Puzzle Solver</h1>
        <p className="text-xl text-gray-600">
          Complete your puzzles faster with AI-powered piece detection and matching
        </p>
      </div>

      {/* How it Works */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
        {features.map((feature, index) => (
          <div 
            key={feature.title}
            className="bg-white rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow text-center"
          >
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <feature.icon className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Tips for Best Results */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-12">
        <h2 className="text-2xl font-bold mb-4">Tips for Best Results</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {tips.map((tip, index) => (
            <div key={index} className="flex items-start space-x-2">
              <div className="w-6 h-6 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                <span className="text-green-600 font-medium">{index + 1}</span>
              </div>
              <p className="text-gray-700">{tip}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Getting Started Button */}
      <div className="text-center">
        <button 
          onClick={() => window.location.href = '/solve'}
          className="bg-blue-500 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-blue-600 transition-colors"
        >
          Start Solving
        </button>
      </div>

      <footer className="mt-12 pt-6 border-t text-center text-gray-500 text-sm">
        <p>
          Favicon: <a href="https://github.com/twitter/twemoji" className="text-blue-500 hover:text-blue-600">Twemoji</a> by Twitter, 
          licensed under <a href="https://creativecommons.org/licenses/by/4.0/" className="text-blue-500 hover:text-blue-600">CC-BY 4.0</a>
        </p>
      </footer>
    </div>
  );
};

export default HomePage;