// src/pages/AboutPage.js

import React from 'react';
import { Wrench, Brain, Code, Heart } from 'lucide-react'; // Changed Tool to Wrench

const AboutPage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">About Puzzle Solver</h1>
        <p className="text-xl text-gray-500">
          Using technology to make puzzle solving more enjoyable
        </p>
      </div>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:gap-12">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center mb-4">
            <Wrench className="h-6 w-6 text-blue-500 mr-2" /> {/* Changed from Tool to Wrench */}
            <h2 className="text-xl font-semibold">How It Works</h2>
          </div>
          <p className="text-gray-600">
            Our puzzle solver uses advanced image processing and pattern matching
            algorithms to analyze puzzle pieces and suggest possible matches.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center mb-4">
            <Brain className="h-6 w-6 text-blue-500 mr-2" />
            <h2 className="text-xl font-semibold">Technology</h2>
          </div>
          <p className="text-gray-600">
            Built with React, Tailwind CSS, and modern image processing techniques.
            We're constantly improving our algorithms to provide better suggestions.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center mb-4">
            <Code className="h-6 w-6 text-blue-500 mr-2" />
            <h2 className="text-xl font-semibold">Open Source</h2>
          </div>
          <p className="text-gray-600">
            This project is open source and welcomes contributions from the community.
            Visit our GitHub repository to learn more and get involved.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center mb-4">
            <Heart className="h-6 w-6 text-blue-500 mr-2" />
            <h2 className="text-xl font-semibold">Support</h2>
          </div>
          <p className="text-gray-600">
            Found a bug or have a suggestion? Visit our GitHub repository to open an
            issue or contribute to the project.
          </p>
        </div>
      </div>

      <div className="mt-12 text-center">
        <p className="text-gray-500">
          Created with passion for puzzle enthusiasts everywhere.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;